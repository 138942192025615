import { createReducer } from '@reduxjs/toolkit';

import { getGuacInfoAction } from '../actions';
import {
  connectedStatusOverviewAction,
  getApplyOverviewAction,
  getPortalsAction,
  getPortalsInfoAction,
  getSchedulersAction,
  getStageOverviewAction,
  getUserAnalyticsAction,
  setPortalInfoAction,
  sseEventAction,
} from '../actions/dataManagementAction';

export type getSchedulers = {
  schedulersData: [];
  schedulersPending: boolean;
  schedulersError: boolean;
  schedulersSuccess: boolean;
  schedulersErrorData: {};
  totalSkippedJobs: number;
  totalFoundJobs: number;
  totalAppliedJobs: number;
  disableApplyButton: boolean;
};

const schedulersInitialState: getSchedulers = {
  schedulersData: [],
  schedulersPending: false,
  schedulersError: false,
  schedulersSuccess: false,
  schedulersErrorData: {},
  totalSkippedJobs: 0,
  totalFoundJobs: 0,
  totalAppliedJobs: 0,
  disableApplyButton: false,
};

export const schedulerReducer = createReducer(
  schedulersInitialState,
  (builder) => {
    builder
      .addCase(getSchedulersAction.pending, (state) => {
        state.schedulersPending = true;
      })
      .addCase(getSchedulersAction.fulfilled, (state, { payload }) => {
        const totals = payload.reduce(
          (acc: any, curr: any) => {
            acc.totalSkippedJobs += curr.skipped_jobs;
            acc.totalFoundJobs += curr.found_jobs;
            acc.totalAppliedJobs += curr.applied_jobs;
            return acc;
          },
          { totalSkippedJobs: 0, totalFoundJobs: 0, totalAppliedJobs: 0 }
        );

        const getStatus = payload.every((job: any) => job.is_completed);
        state.schedulersPending = false;
        state.schedulersData = payload;
        state.schedulersSuccess = true;
        state.totalSkippedJobs = totals.totalSkippedJobs;
        state.totalFoundJobs = totals.totalFoundJobs;
        state.totalAppliedJobs = totals.totalAppliedJobs;
        state.disableApplyButton = getStatus;
      })
      .addCase(getSchedulersAction.rejected, (state, { payload }) => {
        state.schedulersPending = false;
        state.schedulersError = true;
        state.schedulersErrorData = payload;
        state.schedulersSuccess = false;
      })
      .addCase(sseEventAction.fulfilled, (state, { payload }) => {
        const { data, init } = payload;
        if (!init) {
          const dynamicKey = Object.keys(data)[0];
          const applyingObject = data[dynamicKey].APPLYING;
          const paginationObject = data[dynamicKey].PAGINATION;

          console.log('payload', data);
          state.schedulersData = state.schedulersData.map((item: any) => {
            if (item.uuid === dynamicKey) {
              // Start with the current item
              let updatedItem = { ...item };

              // If applyingObject is present, update the relevant fields
              if (applyingObject) {
                updatedItem = {
                  ...updatedItem,
                  applied_jobs: applyingObject.applied,
                  skipped_jobs: applyingObject.failed,
                };
              }
              // If paginationObject is present, update the relevant fields
              if (paginationObject) {
                updatedItem = {
                  ...updatedItem,
                  found_jobs: paginationObject.count,
                };
              }
              return updatedItem;
            }
            return item;
          });
          state.schedulersPending = false;
          state.schedulersError = true;
          state.schedulersErrorData = {};
          state.schedulersSuccess = false;
        }
      });
  }
);

export type getPortalInfo = {
  getPortalInfoData: [];
  getPortalInfoPending: boolean;
  getPortalInfoError: boolean;
  getPortalInfoSuccess: boolean;
  getPortalInfoErrorData: {};
  portalInfo: {};
};

const getPortalInfoInitialState: getPortalInfo = {
  getPortalInfoData: [],
  getPortalInfoPending: false,
  getPortalInfoError: false,
  getPortalInfoSuccess: false,
  getPortalInfoErrorData: {},
  portalInfo: {},
};

export const getPortalInfoReducer = createReducer(
  getPortalInfoInitialState,
  (builder) => {
    builder
      .addCase(getPortalsInfoAction.pending, (state) => {
        state.getPortalInfoPending = true;
      })
      .addCase(getPortalsInfoAction.fulfilled, (state, { payload }) => {
        state.getPortalInfoPending = false;
        state.getPortalInfoData = payload;
        state.getPortalInfoSuccess = true;
      })
      .addCase(getPortalsInfoAction.rejected, (state, { payload }) => {
        state.getPortalInfoPending = false;
        state.getPortalInfoError = true;
        state.getPortalInfoErrorData = payload;
        state.getPortalInfoSuccess = false;
      })
      .addCase(setPortalInfoAction.fulfilled, (state, { payload }) => {
        const portalInfo = state.getPortalInfoData.find(
          (item: any) => item?.ProfileLinkedJobPortal.job_portal.id == payload
        );
        state.portalInfo = portalInfo;
      });
  }
);

export type getPortals = {
  getPortalData: [];
  getPortalPending: boolean;
  getPortalError: boolean;
  getPortalSuccess: boolean;
  getPortalErrorData: {};
};

const getPortalInitialState: getPortals = {
  getPortalData: [],
  getPortalPending: false,
  getPortalError: false,
  getPortalSuccess: false,
  getPortalErrorData: {},
};

export const getPortalsReducer = createReducer(
  getPortalInitialState,
  (builder) => {
    builder
      .addCase(getPortalsAction.pending, (state) => {
        state.getPortalPending = true;
      })
      .addCase(getPortalsAction.fulfilled, (state, { payload }) => {
        state.getPortalPending = false;
        state.getPortalData = payload;
        state.getPortalSuccess = true;
      })
      .addCase(getPortalsAction.rejected, (state, { payload }) => {
        state.getPortalPending = false;
        state.getPortalError = true;
        state.getPortalErrorData = payload;
        state.getPortalSuccess = false;
      });
  }
);

export type getOverviewData = {
  getOverviewData: [];
  getOverviewDataPending: boolean;
  getOverviewDataError: boolean;
  getOverviewDataSuccess: boolean;
  getOverviewDataErrorData: {};
};

const getOverviewDataInitialState: getOverviewData = {
  getOverviewData: [],
  getOverviewDataPending: false,
  getOverviewDataError: false,
  getOverviewDataSuccess: false,
  getOverviewDataErrorData: {},
};

export const getOverviewDataReducer = createReducer(
  getOverviewDataInitialState,
  (builder) => {
    builder
      .addCase(connectedStatusOverviewAction.pending, (state) => {
        state.getOverviewDataPending = true;
      })
      .addCase(
        connectedStatusOverviewAction.fulfilled,
        (state, { payload }) => {
          state.getOverviewDataPending = false;
          state.getOverviewData = payload;
          state.getOverviewDataSuccess = true;
        }
      )
      .addCase(connectedStatusOverviewAction.rejected, (state, { payload }) => {
        state.getOverviewDataPending = false;
        state.getOverviewDataError = true;
        state.getOverviewDataErrorData = payload;
        state.getOverviewDataSuccess = false;
      });
  }
);

export type getApplyOverviewData = {
  getApplyOverviewData: [];
  getApplyOverviewDataPending: boolean;
  getApplyOverviewDataError: boolean;
  getApplyOverviewDataSuccess: boolean;
  getApplyOverviewDataErrorData: {};
};

const getApplyOverviewDataInitialState: getApplyOverviewData = {
  getApplyOverviewData: [],
  getApplyOverviewDataPending: false,
  getApplyOverviewDataError: false,
  getApplyOverviewDataSuccess: false,
  getApplyOverviewDataErrorData: {},
};

export const getApplyOverviewDataReducer = createReducer(
  getApplyOverviewDataInitialState,
  (builder) => {
    builder
      .addCase(getApplyOverviewAction.pending, (state) => {
        state.getApplyOverviewDataPending = true;
      })
      .addCase(getApplyOverviewAction.fulfilled, (state, { payload }) => {
        state.getApplyOverviewDataPending = false;
        state.getApplyOverviewData = payload;
        state.getApplyOverviewDataSuccess = true;
      })
      .addCase(getApplyOverviewAction.rejected, (state, { payload }) => {
        state.getApplyOverviewDataPending = false;
        state.getApplyOverviewDataError = true;
        state.getApplyOverviewDataErrorData = payload;
        state.getApplyOverviewDataSuccess = false;
      });
  }
);

export type getStageOverviewData = {
  getStageOverviewData: [];
  getStageOverviewDataPending: boolean;
  getStageOverviewDataError: boolean;
  getStageOverviewDataSuccess: boolean;
  getStageOverviewDataErrorData: {};
};

const getStageOverviewDataInitialState: getStageOverviewData = {
  getStageOverviewData: [],
  getStageOverviewDataPending: false,
  getStageOverviewDataError: false,
  getStageOverviewDataSuccess: false,
  getStageOverviewDataErrorData: {},
};

export const getStageOverviewDataReducer = createReducer(
  getStageOverviewDataInitialState,
  (builder) => {
    builder
      .addCase(getStageOverviewAction.pending, (state) => {
        state.getStageOverviewDataPending = true;
      })
      .addCase(getStageOverviewAction.fulfilled, (state, { payload }) => {
        state.getStageOverviewDataPending = false;
        state.getStageOverviewData = payload;
        state.getStageOverviewDataSuccess = true;
      })
      .addCase(getStageOverviewAction.rejected, (state, { payload }) => {
        state.getStageOverviewDataPending = false;
        state.getStageOverviewDataError = true;
        state.getStageOverviewDataErrorData = payload;
        state.getStageOverviewDataSuccess = false;
      });
  }
);

export type getUserAnalyticsData = {
  getUserAnalyticsData: {};
  getUserAnalyticsDataPending: boolean;
  getUserAnalyticsDataError: boolean;
  getUserAnalyticsDataSuccess: boolean;
  getUserAnalyticsDataErrorData: {};
};

const getUserAnalyticsDataInitialState: getUserAnalyticsData = {
  getUserAnalyticsData: {
    applied: {},
    discovery: [],
  },
  getUserAnalyticsDataPending: false,
  getUserAnalyticsDataError: false,
  getUserAnalyticsDataSuccess: false,
  getUserAnalyticsDataErrorData: {},
};

export const getUserAnalyticsDataReducer = createReducer(
  getUserAnalyticsDataInitialState,
  (builder) => {
    builder
      .addCase(getUserAnalyticsAction.pending, (state) => {
        state.getUserAnalyticsDataPending = true;
      })
      .addCase(getUserAnalyticsAction.fulfilled, (state, { payload }) => {
        const topLevelStats = {
          foundJobs: payload.foundJobs,
          appliedJobs: payload.appliedJobs,
          failedJobs: payload.failedJobs,
        };

        const transformedData = {
          ...{ 0: topLevelStats },
          ...payload.linkedPortals,
        };
        const analytics = {
          applied: transformedData,
          discovery: payload.discovery_jobs,
        };
        state.getUserAnalyticsDataPending = false;
        state.getUserAnalyticsData = analytics;
        state.getUserAnalyticsDataSuccess = true;
      })
      .addCase(getUserAnalyticsAction.rejected, (state, { payload }) => {
        state.getUserAnalyticsDataPending = false;
        state.getUserAnalyticsDataError = true;
        state.getUserAnalyticsDataErrorData = payload;
        state.getUserAnalyticsDataSuccess = false;
      });
  }
);

export type sseEventData = {
  sseEventData: {};
  sseEventDataPending: boolean;
  sseEventDataError: boolean;
  sseEventDataSuccess: boolean;
  sseEventDataErrorData: {};
};

const sseEventDataInitialState: sseEventData = {
  sseEventData: {},
  sseEventDataPending: false,
  sseEventDataError: false,
  sseEventDataSuccess: false,
  sseEventDataErrorData: {},
};

export const sseEventDataReducer = createReducer(
  sseEventDataInitialState,
  (builder) => {
    builder
      .addCase(sseEventAction.pending, (state) => {
        state.sseEventDataPending = true;
      })
      .addCase(sseEventAction.fulfilled, (state, { payload }) => {
        const { data } = payload;
        state.sseEventDataPending = false;
        state.sseEventData = { ...state.sseEventData, ...data };
        state.sseEventDataSuccess = true;
      })
      .addCase(sseEventAction.rejected, (state, { payload }) => {
        state.sseEventDataPending = false;
        state.sseEventDataError = true;
        state.sseEventDataErrorData = payload;
        state.sseEventDataSuccess = false;
      });
  }
);

export type GuacInfoSync = {
  guacInfoData: {};
  guacInfoPending: boolean;
  guacInfoError: boolean;
  guacInfoSuccess: boolean;
  guacInfoErrorData: {};
};

const getGuacInfoSyncInitialState: GuacInfoSync = {
  guacInfoData: {},
  guacInfoPending: false,
  guacInfoError: false,
  guacInfoSuccess: false,
  guacInfoErrorData: {},
};

export const guacInfoReducer = createReducer(
  getGuacInfoSyncInitialState,
  (builder) => {
    builder

      .addCase(getGuacInfoAction.pending, (state) => {
        state.guacInfoPending = true;
      })
      .addCase(getGuacInfoAction.fulfilled, (state, { payload }) => {
        state.guacInfoPending = false;
        state.guacInfoData = payload;
        state.guacInfoSuccess = true;
      })
      .addCase(getGuacInfoAction.rejected, (state, { payload }) => {
        state.guacInfoPending = false;
        state.guacInfoError = true;
        state.guacInfoErrorData = payload;
        state.guacInfoSuccess = false;
      });
  }
);

import {
  Action,
  AnyAction,
  Store,
  ThunkAction,
  combineReducers,
  configureStore,
} from '@reduxjs/toolkit';
import { HYDRATE, createWrapper } from 'next-redux-wrapper';
import scheduleApplyingReducer, {
  addApplicantReducer,
  appliedAnalyticsReducer,
  getFailedOrUserJobsReducer,
  getJobStagesCountReducer,
  startOrStopApplyingReducer,
} from './reducers/jobReducer';
import profileReducer, {
  addSearchUrlsReducer,
  assignedProfileReducer,
  deleteNamespaceDataReducer,
  disConnectWithProfilePortalReducer,
  stepDirectionReducer,
  stepFormDataReducer,
  uploadResumeReducer,
} from './reducers/profileReducer';
import roleReducer from './reducers/roleReducer';
import addUserReducer, {
  deleteUserReducer,
  getIdTokenResultReducer,
  getUsersReducer,
  inviteUserCheckingReducer,
  updateUserPasswordReducer,
} from './reducers/userReducer';

import {
  nextReduxCookieMiddleware,
  wrapMakeStore,
} from 'next-redux-cookie-wrapper';
import thunk from 'redux-thunk';
import {
  getApplyOverviewDataReducer,
  getOverviewDataReducer,
  getPortalInfoReducer,
  getPortalsReducer,
  getStageOverviewDataReducer,
  getUserAnalyticsDataReducer,
  guacInfoReducer,
  schedulerReducer,
  sseEventDataReducer,
} from './reducers/dataReducer';
import getDiscoveryJobsReducer, {
  startDiscoveringReducer,
} from './reducers/getDiscoveryJobsReducer';
import {
  getPlanInfoReducer,
  getStripeProductsReducer,
} from './reducers/paymentReducer';
import eventsSlice from './slices/eventsSlice';

const combinedReducer = combineReducers({
  roles: roleReducer,
  appliedAnalytics: appliedAnalyticsReducer,
  profiles: profileReducer,
  assignedProfiles: assignedProfileReducer,
  addUser: addUserReducer,
  getUsers: getUsersReducer,
  addSearchUrls: addSearchUrlsReducer,
  inviteUserChecking: inviteUserCheckingReducer,
  updateUserPassword: updateUserPasswordReducer,
  getIdTokenResult: getIdTokenResultReducer,
  scheduleJobApplying: scheduleApplyingReducer,
  applicantDetails: addApplicantReducer,
  startOrStopApplying: startOrStopApplyingReducer,
  getPlanInfo: getPlanInfoReducer,
  getDiscoveryJobs: getDiscoveryJobsReducer,
  uploadResume: uploadResumeReducer,
  stepForm: stepFormDataReducer,
  stepDirection: stepDirectionReducer,
  getStripeProductV2: getStripeProductsReducer,
  deleteFetchJobNamespace: deleteNamespaceDataReducer,
  startDiscoveringJobs: startDiscoveringReducer,
  eventsData: eventsSlice,
  failedOrUserJobs: getFailedOrUserJobsReducer,
  deleteUser: deleteUserReducer,
  disConnectProfilePortal: disConnectWithProfilePortalReducer,
  scheduler: schedulerReducer,
  getPortalInfo: getPortalInfoReducer,
  getPortals: getPortalsReducer,
  getOverviewData: getOverviewDataReducer,
  getApplyOverview: getApplyOverviewDataReducer,
  getStageOverview: getStageOverviewDataReducer,
  sseEventData: sseEventDataReducer,
  getUserAnalyticsData: getUserAnalyticsDataReducer,
  getJobStagesCount: getJobStagesCountReducer,
  guacInfo: guacInfoReducer,
});

const reducer = (
  state: ReturnType<typeof combinedReducer>,
  action: AnyAction
) => {
  if (action.type === HYDRATE) {
    const nextState = {
      ...state, // use previous state
      ...action.payload, // apply delta from hydration
      getStripeProductV2: state.getStripeProductV2,
    };
    return nextState;
  } else {
    return combinedReducer(state, action);
  }
};

export const makeStore = wrapMakeStore(() =>
  configureStore({
    reducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware()
        .prepend(
          nextReduxCookieMiddleware({
            subtrees: [
              'getIdTokenResult',
              {
                subtree: `getIdTokenResult.idTokenResult`,
                cookieName: 'NEXT_ID_TOKEN_RESULT',
                serializationFunction: String,
                deserializationFunction: String,
              },
              'getPlanInfo',
              {
                subtree: `getPlanInfo.planInfo`,
                cookieName: 'NEXT_ID_PLAN_INFO',
                serializationFunction: String,
                deserializationFunction: String,
              },
              // 'profiles',
              // {
              //   subtree: `profiles.profiles`,
              //   cookieName: 'NEXT_ID_PROFILES',
              //   serializationFunction: String,
              //   deserializationFunction: String,
              // },
            ],
          })
        )
        .concat(thunk),
  })
);

export type AppDispatch = Store['dispatch'];
export type RootState = ReturnType<Store['getState']>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export const wrapper = createWrapper(makeStore);
